export enum ConfirmationStatusFilterValues {
  All = 'All',
  Confirmed = 'Confirmed',
  NotConfirmed = 'Not Confirmed'
}

export enum OrderStatusFilterValues {
  All = 'All',
  PickedUp = 'Picked-Up',
  NotPickedUp = 'Not Picked-Up',
  BoughtOut = 'Bought-out'
}
